import React from "react";
import MainLayout from "../components/Layout/MainLayout";
import styled from "styled-components";
import { H1 } from "../components/Styles/Typography";
import { Icon } from "../components/Icons/Icons";
import { ButtonLink } from "../components/Styles/Buttons";
import {
  BackgroundImage,
  FallbackBackgroundImage,
} from "../components/Styles/BackgroundImage";
import fallbackImage from "../../static/fallback-image.jpg";
import { graphql } from "gatsby";

const LatestNews = React.memo(({ data, location }) => {
  const {
    content,
    featured_media,
    title,
    acf,
  } = data.allWordpressPage.edges[0].node;

  const { latest_news_links } = acf;

  return (
    <MainLayout location={location}>
      <Img className="container-fluid">
        {featured_media && featured_media.localFile ? (
          <BackgroundImage
            sizes={featured_media.localFile.childImageSharp.fluid}
          />
        ) : (
          <FallbackBackgroundImage
            style={{ backgroundImage: `url(${fallbackImage})` }}
          />
        )}
        <IntroBody>
          <H1>{title}</H1>
        </IntroBody>
      </Img>
      <Paragraph className="container">
        <div className="row">
          <div
            className="col-xs-12 col-md-8 col-md-offset-2"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </Paragraph>
      <div className="container">
        <div className="row">
          <NewsItems className="col-xs-12">
            {latest_news_links.map(
              ({ url, headline, image }, index) =>
                index < 8 ? (
                  <NewsItem key={index}>
                    <NewsItemMedia
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer">
                      <BgImg
                        style={{
                          backgroundImage: `url(${
                            image && image.localFile
                              ? image.localFile.childImageSharp.fluid.src
                              : fallbackImage
                          })`,
                        }}
                      />
                      <MediaLink>
                        <Icon name="link" iconHeight={40} iconWidth={40} />
                      </MediaLink>
                    </NewsItemMedia>
                    <NewsItemBody>
                      <h3>{headline}</h3>
                      <ButtonLink
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer">
                        Read more
                      </ButtonLink>
                    </NewsItemBody>
                  </NewsItem>
                ) : (
                  <NewsLink url={url} headline={headline} key={index} />
                )
            )}
          </NewsItems>
        </div>
      </div>
    </MainLayout>
  );
});

const NewsLink = ({ headline, url }) => {
  return (
    <NewsLinkStyled href={url}>
      <Icon name="link" iconHeight={15} iconWidth={15} />
      <span>{headline}</span>
    </NewsLinkStyled>
  );
};

const BgImg = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`;

const MediaLink = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.25s ease-out;
  top: 0;
  left: 0;
  color: white;
  z-index: 2;
`;

const NewsItemMedia = styled.a`
  height: 380px;
  width: 100%;
  margin-bottom: 1rem;
  overflow: hidden;
  border-radius: 4px;
  display: block;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.mainAccent};
    z-index: 1;
    opacity: 0;
    transition: opacity 0.65s ease-out;
  }

  :hover {
    &::after {
      opacity: 0.4;
    }

    ${MediaLink} {
      opacity: 1;
    }
  }
`;

const NewsItems = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  justify-content: space-between;
`;

const NewsItemBody = styled.div`
  overflow: hidden;

  h3 {
    font-size: 20px;
    color: #0f2522;
    letter-spacing: 0;
  }

  small {
    opacity: 0.7;
    font-size: 14px;
    color: #0a0724;
    letter-spacing: 0;
    padding-bottom: 0.25rem;
  }

  a {
    font-size: 13px;
    color: ${props => props.theme.mainAccent};
    letter-spacing: 2px;
    text-align: center;
    line-height: 1;
  }
`;

const NewsItem = styled.div`
  width: calc(100%);
  margin-bottom: 1.5rem;

  ${NewsItemMedia} {
    height: 120px;
  }

  ${NewsItemBody} {
    h3 {
      min-height: 62px;
      line-height: 1.4;
    }
  }

  @media (min-width: ${props => props.theme.md}) {
    width: calc(50% - 1rem);
    margin-bottom: 0;

    ${NewsItemMedia} {
      height: 170px;
    }
  }

  @media (min-width: ${props => props.theme.lg}) {
    width: calc(25% - 1rem);
  }
`;

const Img = styled.div`
  height: 50vh;
  width: 100%;
  top: -100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IntroBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
  padding-top: 3rem;
`;

const Paragraph = styled.div`
  margin-bottom: 5.5rem;
  text-align: center;
`;

const NewsLinkStyled = styled.a`
  width: 100%;
  margin: 1rem 0;
  border: 1px solid ${props => props.theme.mainLight3};
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: border-color 0.25s ease-out;
  cursor: pointer;

  .icon-wrapper {
    margin-right: 1rem;
  }

  span {
    flex-grow: 1;
    font-size: 14px;
  }

  &:last-of-type {
    margin-bottom: 4rem;
  }

  &:hover {
    border-color: ${props => props.theme.mainAccent};
  }
`;

export const LatestNewsQuery = graphql`
  query LatestNewsQuery {
    allWordpressPage(filter: { slug: { regex: "/latest-news/" } }) {
      edges {
        node {
          acf {
            latest_news_links {
              url
              headline
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 400) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          slug
          title
          content
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default LatestNews;
